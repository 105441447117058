<template>
  <div class="news-item">
    <p class="title">
      {{ data.title }}
    </p>
    <div class="content-wrap">
      <img class="photo" :src="data.img" />
      <div class="info-wrap">
        <div class="info-notice">{{data.notice}}</div>
        <div class="info-content">
          {{ data.content }}
        </div>
        <div class="external-wrap"> 
          <ExternalItem v-for="bottomItem in data.bottomList" :key="bottomItem.key" :data="bottomItem" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ExternalItem from '@/components/news/ExternalItem.vue'
export default {
  name: 'MemberItem',
  components: {
    ExternalItem
  },
  props: {
    data: {
      type: Object,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
.news-item {
  width: 100%;
  text-align: start;

  .title {
    padding: 12px;
    font-size: 20px;
    font-weight: 700;
    letter-spacing: 6px;
  }

  .content-wrap {
    display: flex;
    gap: 56px;

    @include ipad {
      flex-direction: column;
      align-items: center;
      gap: 24px;
    }
  }

  .photo {
    width: 400px;
    height: fit-content;

    @include ipad {
      width: 100%;
    }
  }
  
  .info-wrap {
    width: 100%;
    max-width: 600px;
    display: flex;
    flex-direction: column;
    gap: 24px;

    @include ipad {
      flex-direction: column;
      align-items: center;
      gap: 36px;
    }
  }

  .info-notice {
    font-size: 20px;
    color: brown;
  }

  .info-content {
    font-size: 16px;
  }

  .external-wrap {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
}
</style>
